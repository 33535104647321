<template>
  <section class="detailCase fade-in" v-bind="initSocketEvents">
    <div class="detailCase__wrapper">
      <div class="detailCase__return" @click="go">
        <i class="fas fa-arrow-left"></i>
        Volver a tickets
      </div>
      <div class="detailCase__Update gcard">
        <div class="detailCase__containerI">
          <div class="detailCase__ticketNumbers">
            <p class="detailCase__ticketNumber-text">
              {{ " Ticket #" + ticketNumbers() }}
            </p>
          </div>
          <div class="detailCase__inputs__wrapper">
            <div class="detailCase__inputs " :disabled="cases.status === 'close'">
              <iconic class="detailCase__inputs-iconic" name="filter"></iconic>
              <select
                :disabled="cases.status === 'close' || $userData.role === 'model'"
                class="detailCase__inputs-select"
                name="area"
                v-model="selectarea"
                @change="changeArea"
              >
                <option :value="are._id" v-for="(are, idx) in area" :key="idx">
                  {{ are.name }}
                </option>
              </select>
            </div>
            <div class="detailCase__inputs " v-if="disableByrole()">
              <iconic class="detailCase__inputs-iconic" name="filter"></iconic>
              <select class="detailCase__inputs-select" :disabled="cases.status === 'close'" name="priority" v-model="selectpriority">
                <option :value="are.value" v-for="(are, idx) in priority" :key="idx">
                  {{ are.name }}
                </option>
              </select>
            </div>
            <div class="detailCase__inputs g " v-if="disableByrole() && validAreaDefault">
              <div>
                <iconic class="detailCase__inputs-iconic" name="search"></iconic>
                <input
                  :disabled="cases.status === 'close'"
                  v-model="search"
                  @input="search ? ((clickSearch = true), searchUsers()) : (clickSearch = false)"
                  class="detailCase__inputs-search ginput"
                  type="text"
                  name="assignament"
                  placeholder="Asignación de caso"
                />
                <div class="results" v-if="clickSearch">
                  <div class="spinner" v-if="isLoadingSearh"></div>
                  <div v-for="(user, idx) in usersTo" :key="idx">
                    <span @click="setUserCase(user)">{{ user.user }}</span>
                  </div>
                  <div v-if="!usersTo.length && !isLoadingSearh">Sin personal</div>
                </div>
              </div>
            </div>
            <div>
              <button :class="['detailCase__inputs-updateCase  ginput', validButton() ? 'red' : 'gray']" @click="validButton() ? updateCases() : ''">
                Actualizar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="detailCase__chat gcard">
        <div class="detailCase__content">
          <div class="detailCase__content-date">
            <div class="detailCase__avatar">
              <h5 class="detailCase__title">
                {{ typeRequirements() }}
              </h5>
              <div class="detailCase__info" v-if="!isLoading">
                <p>Fecha de solicitud: {{ $global.dateLocaleFormat(cases.date, options) }}</p>
              </div>
              <div class="detailCase__info" v-if="!isLoading">
                <p>Detalle de ubicación: {{ location }}</p>
              </div>
            </div>
          </div>
          <div class="detailCase__button">
            <button class="detailCase__green" v-if="cases.status !== 'close'" @click="showModal = true">
              <span class="detailCase__green-text">
                Solucionar
              </span>
              <iconic class="detailCase__green-iconic" name="aprove"></iconic>
            </button>
          </div>
        </div>
        <div class="detailCase__chatReal">
          <MessageCard :media="showModalFilesAction" v-if="!isLoading" :idx="validUser" :message="createMessage" />
          <div class="click-on-bottom" v-if="!isLoading && hasMore && scrooll" @click="casePagination"></div>
          <div class="detailCase__contentChat" :key="resetChat">
            <MessageCard
              v-for="(datos, idx) in userCom || []"
              :key="idx"
              :media="showModalFilesAction"
              :idx="order(idx)"
              :message="datos"
              :nextMsg="userCom[idx - 1]"
            />
          </div>
          <div class="spinner" v-if="isLoading && scrooll && !showLine"></div>
        </div>
      </div>
      <p>
        {{ textFiles }}
      </p>
      <div class="detailCase__response gcard">
        <div class="detailCase__response-content">
          <span>
            <presentagelineVue v-if="showLine" :line="presentage" :number="+presentage" :color="'green'"></presentagelineVue>
          </span>
          <input
            v-if="cases.status !== 'close'"
            class="detailCase__response-input"
            v-model="response"
            @keypress.enter="response.trim() && !isLoading ? sendMessages() : ''"
            type="text"
            name=""
            placeholder="Escribe una respuesta"
          />
        </div>
        <p class="">{{ filesName.length ? filesName : "" }}</p>
        <div class="detailCase__response-button">
          <div class="detailCase__response-file" v-if="cases.status !== 'close'">
            <label for="fileResponse">
              <iconic name="clip"></iconic>
              Adjuntar archivo
            </label>
            <input
              accept="audio/*,video/*,image/*,media-type"
              @input="setFile"
              type="file"
              id="fileResponse"
              multiple
              style="visibility:hidden;"
              placeholder="Adjuntar archivo"
            />
          </div>
          <button v-if="cases.status !== 'close'" class="detailCase__response-butto" @click="!isLoading ? sendMessages() : ''">
            Enviar
          </button>
        </div>
      </div>
    </div>
    <ModalVue @onSubmit="closeCase()" @onCancel="closeModal" :isDisableSubmit="!descriptionClose.trim()" v-if="showModal">
      <template v-slot:body>
        <div class="detailCase__modal">
          <h3 class="detailCase__modal-p">
            ¿Estás seguro de cerrar el caso?
          </h3>
          <textarea ginput class="detailCase__modal-input" v-model="descriptionClose" placeholder="Agrega el porque cierras el caso."></textarea>
        </div>
      </template>
    </ModalVue>
    <modalFilesHelpVue :actions="actionsClose" :media="media" v-if="showModalFiles"></modalFilesHelpVue>
  </section>
</template>

<script>
import mixinCrud from "./mixinCrud";
import MessageCard from "./messageCard.vue";
import presentagelineVue from "../input/presentageline.vue";
import { socket } from "@/socket";
import ModalVue from "../modals/Modal.vue";
import modalFilesHelpVue from "./modalFilesHelp.vue";
export default {
  name: "detailCase",
  mixins: [mixinCrud],
  components: {
    MessageCard,
    presentagelineVue,
    ModalVue,
    modalFilesHelpVue,
  },
  data() {
    return {
      page: 0,
      isLoading: false,
      cases: {},
      area: "",
      typesrequirements: "",
      response: "",
      ant: "",
      time: "",
      scrooll: true,
      selectarea: "",
      selectpriority: "",
      selectstatus: "",
      clickSearch: false,
      search: "",
      isLoadingSearh: false,
      usersTo: [],
      userAsigned: "",
      file: [],
      textFiles: "",
      presentage: "0",
      showLine: false,
      showModal: false,
      descriptionClose: "",
      showModalFiles: false,
      media: [],
      options: { month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", hourCycle: "h12" },
      resetChat: 1,
      timeOut: null,
      tiemOutScroll: null,
    };
  },
  computed: {
    location() {
      const { city = "", office = "", room = "", location = "" } = this.cases;
      return `${city}${office && " - " + office}${room && " - " + room}${location && " - " + location}`;
    },
    userCom() {
      return this.$store.state.helpdesk.caseMessages;
    },
    hasMore() {
      return this.$store.state.helpdesk.hasMore;
    },
    validUser() {
      return this.$userData._id !== this.cases.whoCreate?._id;
    },
    createMessage() {
      return { idUser: this.cases.whoCreate, description: this.cases.description, date: this.cases.date, files: this.cases.files };
    },
    filesName() {
      let data = "";
      Object.keys(this.file).forEach((key) => {
        data += this.file[key]?.name + " ";
      });
      return data;
    },
    initSocketEvents() {
      if (this.$socketState) {
        socket.on("progressmessage", this.onloadPresentage);
        socket.on("newMessageHelpDesk", (data) => {
          if (!data) return;
          if (this.$store.state.helpdesk.idCase !== data.idRelation) return;

          this.$store.dispatch("helpdesk/setNewMessage", data);
          this.resetChat++;

          clearTimeout(this.tiemOutScroll);
          this.tiemOutScroll = setTimeout(() => {
            this.scrolldown();
          }, 100);

          if (this.$userData._id === this.cases.whoCreate._id && this.$userData._id !== data.idUser._id) {
            socket.emit("messageIsRead", { _id: data._id });
          }
          if (this.$userData._id !== this.cases.whoCreate._id && this.cases.whoCreate._id === data.idUser._id) {
            socket.emit("messageIsRead", { _id: data._id });
          }
        });
      }
    },
    validAreaDefault() {
      if (this.$userData.role === "employee") {
        if (this.selectarea === this.$userData.area) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
  methods: {
    setFile({ srcElement }) {
      this.file = srcElement.files;
    },
    onloadPresentage(dato) {
      this.presentage = `${dato}`;
    },
    showModalFilesAction(media) {
      this.showModalFiles = true;
      this.media = media;
    },
    actionsClose() {
      this.showModalFiles = false;
      this.media = [];
    },
    go() {
      this.$router.push({ path: "/helpdesk/resume", query: this.getQueryToresume() });
    },
    order(idx) {
      if (this.$userData?._id !== this.userCom[+idx].idUser?._id) return true;
      return false;
    },
    getQueryToresume() {
      const query = this.$route.query;
      delete query.id;
      return query;
    },
    async closeCase() {
      this.value = "";
      const updateCase = await this.updateCase({ status: "close", descriptionClose: this.descriptionClose }, this.$route.query.id);
      if (updateCase.data.error || updateCase.error) {
        this.$alerts.dataSendError({ message: "Ocurrio un error en la actualización" });
        this.closeModal();
        return;
      }
      await this.$store.dispatch("helpdesk/getMessages", { id: this.$route.query.id, isRead: true });
      this.cases.status = "close";
      this.$alerts.dataSendSuccess({ message: "Se Actualizado el caso Correctamente" });
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
      this.descriptionClose = "";
    },
    ticketNumbers() {
      let [data, letra] = [this.area];
      if (data)
        [letra] = data?.filter((area) => {
          if (area?._id === this.cases?.area) return area?.name;
        });
      return letra?.name?.substring(0, 2) + (this.cases?.ticket < 10 ? "0" + this.cases?.ticket : this.cases?.ticket);
    },
    typeRequirements() {
      let [data, letra] = [this.typesrequirements];
      if (data)
        [letra] = data?.filter((requirement) => {
          if (requirement?._id === this.cases?.typeRequirement) return requirement?.name;
        });
      return letra?.name;
    },
    changeArea() {
      this.userAsigned = null;
      this.search = "";
    },
    async sendMessages() {
      this.isLoading = true;
      this.showLine = true;
      if (!this.response.trim() && !this.file.length) {
        this.isLoading = false;
        this.showLine = false;
        return;
      }
      const Form = new FormData();
      Form.append("message", this.response || "Archivo");
      Form.append("idCase", this.$route.query.id);
      let sizesFiles = 0;
      if (this.file.length) {
        for (let i = 0; i < this.file.length; i++) {
          Form.append(`file${i}`, this.file[i]);
          sizesFiles += this.file[i].size;
        }
      }
      if (sizesFiles > 1000000000) {
        this.isLoading = false;
        this.$alerts.dataSendError({ message: "El tamaño de los archivos no puede ser mayor a 1GB" });
        this.file = [];
        this.textFiles = "";
        return;
      }
      let response = await this.sendMessage(Form);
      if (response.error) {
        this.isLoading = false;
        this.$alerts.dataSendError({ message: "No se ha podido enviar el mensaje." });
        return;
      }
      this.isLoading = false;
      this.showLine = false;
      this.cleanMessage();
    },
    cleanMessage() {
      this.response = "";
      this.file = "";
    },
    validButton() {
      if (this.cases.status === "close") return false;
      if (this.selectarea && this.selectpriority && this.selectstatus) {
        if (
          this.selectarea !== this.cases.area ||
          this.selectpriority !== this.cases.priority ||
          this.selectstatus !== this.cases.status ||
          (this.userAsigned.length && this.userAsigned !== this.cases.asignament?._id)
        ) {
          return true;
        }
      }
      return false;
    },
    disableByrole() {
      if (this.$userData.role === "superadmin" || this.$userData.role === "coordinator" || this.$userData.role === "employee") {
        return true;
      }
      return false;
    },
    async updateCases() {
      const body = {};
      if (this.selectarea) body.area = this.selectarea;
      if (this.selectpriority) body.priority = this.selectpriority;
      if (this.userAsigned) body.asignament = this.userAsigned;

      const update = await this.updateCase(body, this.$route.query.id);
      if (update.data.error || update.error) {
        this.$alerts.dataSendError({ message: "Ocurrio un error en la actualización" });
        return;
      }
      if (this.selectstatus) this.cases.status = this.selectstatus;
      this.$alerts.dataSendSuccess({ message: "Se Actualizado el caso Correctamente" });
    },
    scrollHeigh() {
      const element = document.querySelector(".detailCase__chatReal");
      if (Math.round(element?.scrollTop < 100)) return true;
      return false;
    },
    scrolldown() {
      const el = document.querySelector(".detailCase__contentChat");
      const elContent = document.querySelector(".detailCase__chatReal");
      elContent.scrollTo({ top: el?.offsetHeight, behavior: "smooth" });
    },
    scrollToBottom() {
      const el = document.querySelector(".detailCase__contentChat");
      const elContent = document.querySelector(".detailCase__chatReal");
      elContent.scrollTo({ top: el?.offsetHeight });
    },
    async searchUsers() {
      this.isLoadingSearh = true;
      this.usersTo = await this.getPersonAsing(this.search, this.selectarea);
      this.userAsigned = "";
      this.isLoadingSearh = false;
    },
    setUserCase(user) {
      this.clickSearch = !this.clickSearch;
      this.userAsigned = user?._id;
      this.search = user.user;
    },
    async casePagination() {
      if (!this.scrollHeigh()) return;
      this.$store.state.helpdesk.page++;
      this.isLoading = true;
      await this.$store.dispatch("helpdesk/getMessages", { id: this.$route.query.id, isRead: true });
      this.isLoading = false;
    },
    whoAsignament() {
      this.userAsigned = this.cases.asignament?._id || "";
      this.search = this.cases.asignament.user || "";
    },
  },
  async beforeMount() {
    this.isLoading = true;
    this.cases = await this.getCasesByid(this.$route.query.id);
    await this.getInfoCases("area", "area");
    await this.getInfoCases("requirement", "typesrequirements");
    await this.$store.dispatch("helpdesk/getMessages", { id: this.$route.query.id, isRead: true });
    this.isLoading = false;
    this.selectarea = this.cases?.area || "";
    this.selectpriority = this.cases?.priority || "";
    this.selectstatus = this.cases?.status || "";
    if (this.cases?.asignament?._id) this.whoAsignament();
    this.scrollToBottom();
    const elPag = document.querySelector(".detailCase__chatReal");
    elPag.addEventListener("scroll", () => {
      elPag.classList.remove("detailCase__chatReal--scrollStop");
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        elPag.classList.add("detailCase__chatReal--scrollStop");
      }, 1000);
    });
  },
  beforeDestroy() {
    this.$store.dispatch("helpdesk/resetMessages");
    this.$el.removeEventListener("scroll", () => {
      clearTimeout(this.timeOut);
    });
  },
};
</script>

<style lang="scss">
.detailCase {
  &__modal {
    &-input {
      margin-top: 20px;
      width: 100%;
      height: 100px;
      outline: none;
      border: solid 1px gray;
      max-height: 150px;
    }
  }
  .modal-g__buttons {
    display: flex;
  }
  &__title {
    color: black;
    font-size: 29px;
  }
  .red {
    color: $primary-color;
    background-color: white;
    border: $primary-color 1px solid;
  }
  &__return {
    margin: 10px 0px;
    padding: 5px;
    color: $primary-color;
    &:hover {
      cursor: pointer;
      background-color: rgba(255, 213, 213, 0.373);
      max-width: 150px;
      border-radius: 5px;
    }
  }
  .gray {
    cursor: not-allowed;
    color: gray;
    background-color: white;
    border: gray 1px solid;
    height: 33px;
  }
  &_filesList {
    width: 70%;
    white-space: pre-wrap;
  }
  &__inputs {
    position: relative;
    width: 100%;
    margin: $mpadding/3;
    [disabled] {
      cursor: not-allowed;
    }
    &__wrapper {
      margin: 5px 15px;
      display: flex;
      @media screen and (max-width: 1150px) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(239px, 160px));
        grid-gap: 0 20px;
      }
    }
    &-updateCase {
      padding: 5px;
      border: 1px solid #ccc;
      width: 150px;
    }
    &-search {
      padding: 5px 4px 1px 26px;
      border: 1px solid #ccc;
      width: 239px;
      height: 33px;
    }
    &-updateCase {
      margin: 5px;
      width: 100%;
    }
    &-iconic {
      position: absolute;
      top: 9.5px;
      left: 10px;
    }
    &-select {
      cursor: pointer;
      appearance: none;
      text-align: start;
      text-transform: capitalize;
      outline: none;
      height: 33px;
      padding: 4px 8px 4px $mpadding * 2;
      color: $black;
      background-color: #f0f0f0;
      border-radius: 4px;
      border: none;
      box-shadow: 0px 0.5px 2px 0px #00000069;
      &:hover {
        background-color: #f0f0f0;
      }
      width: 100%;
    }
  }
  &__chat {
    height: 500px;
    overflow: hidden;
  }
  &__description {
    width: 100%;
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__content,
  &__content-date,
  &__green {
    display: flex;
    justify-content: safe;
    width: 100%;
  }
  &__ticketNumber {
    width: 100%;
    height: 45px;
    &-iconic {
      .iconic {
        font-size: 20px;
      }
      margin: 7px;
    }
    &-text {
      height: 32px;
      margin: 5px 0;
      padding: 4px 5px;
      font-family: $sec_font;
      font-size: 1.25em;
      color: black;
      border-radius: 4px;
    }
  }
  &__containerI {
    width: 100%;
    @include screen_tablet {
      display: flex;
      justify-content: space-between;
    }
  }
  &__ticketNumbers {
    padding: 5px 0;
    display: flex;
    white-space: nowrap;
  }
  &__content {
    border-bottom: 1px solid rgb(208, 207, 207);
    padding: 5px 0;
  }
  &__chat {
    margin-top: 10px;
  }
  &__response {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 10px;
    &-input {
      width: 100%;
      border: none;
      height: 50px;
    }
    &-file {
      width: 130px;
      max-width: 180px;
      font-size: 12px;
      height: 30px;
      padding: 5px;
      background-color: #f0f0f0;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background: rgb(255, 255, 255);
      }
    }
    &-button {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    &-butto {
      background: #bd0909;
      color: #fff;
      border: none;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      width: 100px;
    }
  }
  &__Update {
    overflow: hidden;
    padding: 5px;
    &-text {
      margin-left: 10px;
    }
  }
  &__green {
    border: none;
    background: #0fa90c;
    color: #fff;
    padding: 5px 11px;
    border-radius: 5px;
    &:hover {
      background: #079e04;
    }
    &-iconic {
      margin-top: 2px;
    }
    &-text {
      margin: 0 7px 0 0;
    }
  }
  &__chatReal {
    height: 400px;
    overflow-y: scroll;
    transition: 1s ease-in-out;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 15px;
    }
    &--scrollStop {
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
    }
  }
  &__contentChat {
    display: flex;
    flex-direction: column-reverse;
  }
  [disabled] {
    display: block;
  }
}
.message {
  display: flex;
  &__avatar {
    margin-right: 10px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    &-text {
      margin-top: 10px;
    }
    &-date {
      margin-top: 10px;
      font-size: 12px;
      color: #8c8c8c;
    }
  }
}
.results {
  @media screen and (min-width: 1000px) {
    position: fixed;
  }
  height: 89px;
  width: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border: 1px rgb(211, 211, 211) solid;
  padding: 1px 5px;
  border-radius: 5px;
}
</style>
