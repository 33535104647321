<template>
  <section class="modalHelpDesk fade-in">
    <div gcard class="modalHelpDesk__wrapper">
      <div class="modalHelpDesk__title">
        <h5>
          CONTENIDO MULTIMEDIA
        </h5>
        <button class="modalHelpDesk__closex" @click="actions">
          <iconic name="close"></iconic>
        </button>
      </div>
      <div class="modalHelpDesk__previsualicer">
        <div class="modalHelpDesk__download" v-for="(data, idx) in media" :key="idx">
          <iconic name="media" class="modalHelpDesk__previsualicer-media" />
          <span class="modalHelpDesk__download-text">
            <p class="modalHelpDesk__nameFile">
              {{
                data.name
                  .split("_")
                  .slice(1)
                  .join(" ") || "Sin nombre"
              }}
            </p>
            Tamaño:{{ sizeValue(data.size) }}
          </span>
          <button class="modalHelpDesk__buttonDownload" @click="downloadImg(data)">
            <iconic name="download" class="button"></iconic>
          </button>
        </div>
      </div>
      <div class="modalHelpDesk__toRender" v-if="(media || []).length > 1">
        <button class="modalHelpDesk__downloadAll" :disabled="isLoading" @click="downloadAll(media)">Descargar todo</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["media", "actions"],
  name: "modalFilesHelp",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    downloadAll(data) {
      if (!(data || []).length) {
        return;
      }
      const files = structuredClone(data);
      const file = files.pop();

      this.downloadImg(file);

      const downloadAll = (param) => this.downloadAll(param);
      setTimeout(() => downloadAll(files), 2000);
    },
    downloadImg(data) {
      if (this.isLoading) return;
      this.isLoading = true;
      const url = data.link;

      const frame = document.createElement("iframe");
      frame.style.display = "none";
      frame.src = url;
      frame["download"] = 1;

      document.body.appendChild(frame);
      this.isLoading = false;
    },
    sizeValue(size) {
      return (size * 0.000001).toFixed(2) + " MB";
    },
  },
};
</script>

<style lang="scss">
.modalHelpDesk {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  display: grid;
  align-items: center;
  &__wrapper {
    margin: auto;
    box-shadow: 0 2px 5px #0000008f;
  }
  &__nameFile {
    font-size: 14px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 100px;
    @include pocketWidth {
      max-width: 340px;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  &__buttonDownload {
    margin-left: 5px;
    height: 35px;
    width: 45px;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
    border: none;
    background-color: transparent;
    .iconic {
      height: 20px;
      width: 20px;
    }
    &:hover {
      transition: all 0.4s ease-in-out;
      padding: 5px;
      background-color: rgb(216, 214, 214);
    }
  }
  &__download {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    &-text {
      width: 100%;
    }
  }
  &__close,
  &__closex {
    color: white;
    border: none;
    cursor: pointer;
  }
  &__closex {
    color: $primary-color;
    background-color: white;
    margin-right: 10px;
    font-weight: 800;
  }
  &__close {
    background-color: $primary-color;
  }
  &__previsualicer {
    overflow-y: auto;
    max-height: calc(100vh - 120px);
    max-width: 600px;
    .iconic {
      font-size: 50px;
    }
    &-media {
      width: 100px;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }
  &__toRender {
    @include Flex(row);
    width: 100%;
  }
  &__downloadAll {
    border: none;
    background: $primary-color;
    color: #fff;
    padding: 5px 11px;
    border-radius: 5px;
  }
}
</style>
